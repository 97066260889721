import React, {Component} from 'react'
//import TextField from '@material-ui/core/TextField';
import TextField from '../controls/CustomizedTextbox';
import { Paper, Typography} from '@material-ui/core'
import SubmitButton from '../controls/SubmitButton';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from 'material-ui-pickers';
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});
function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

class AssignmentDetails extends Component {
  constructor(props){
    super(props);
    this.state = {
      showValidation: false
    }
  }
    continue = e => {
      var errors = this.validate();
      var errorLength = 0
      for (var property in errors)
      {
          errorLength += errors[property].length;
      }

      if (errorLength > 0)
      {
          this.setState({showValidation: true})
      }
      else
      {
          this.props.nextStep(); 
      }
    }
    handleDateChange = (field) => date => {
      this.props.handleChangeAlt(field, date);
    };

    validate = () => {
      var errors = {
        agency: '',
        client: '',
        placeOfWork: '',
        startDate: '',
        typeOfWork: '',
        expectedDuration: '',
        servicesProvided: '',
        assignmentRate: '',
      }

      var {values} = this.props;

      if (values.agency.length < 1){
        errors.agency = 'Cannot be blank';
      }
      if (values.client.length < 1){
        errors.client = 'Cannot be blank';
      }
      if (values.placeOfWork.length < 1){
        errors.placeOfWork = 'Cannot be blank';
      }
      if (!isValidDate(values.startDate)){
        errors.startDate = 'Invalid date';
      }
      if (values.typeOfWork.length < 1){
        errors.typeOfWork = 'Cannot be blank';
      }
      if (values.expectedDuration.length < 1){
        errors.expectedDuration = 'Cannot be blank';
      }
      if (values.servicesProvided.length < 1){
        errors.servicesProvided = 'Cannot be blank';
      }
      if (values.assignmentRate.length < 1){
        errors.assignmentRate = 'Cannot be blank';
      }

      return errors;
    }

    render() {
      var errors = this.validate();
      if (!this.state.showValidation){
          //clears all errors for display if they've not clicked the button yet
          for (var property in errors)
          {
              errors[property] = '';
          }
      }
      console.log('rendering assignment details');
      const { classes } = this.props;
        const {values , handleChange, handleChangeAlt } = this.props;

        let showSubmitButton = values.name != undefined && values.name != '' && values.tradeOrProfession != undefined && values.tradeOrProfession != '' && values.email != undefined && values.email != '' && values.tel != undefined && values.tel != '';

        let currentDate = new Date();

        return (
          <React.Fragment>
            <Paper elevation={3} className="p-4 mt-3 mx-auto mb-4" style={{position:"relative", maxWidth: "500px"}}>
            
              <Typography variant="h6" className="mb-3 weightmansheader">
                Assignment details
              </Typography>
              <TextField
                label="Agency"
                value={values.agency}
                onChange={handleChange('agency')}
        
                variant="outlined"
                fullWidth
                error={errors.agency.length > 0}
                helperText={errors.agency}
              />
               <br></br>
               <TextField
                label="Client"
                value={values.client}
                onChange={handleChange('client')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.client.length > 0}
                helperText={errors.client}
              />
              <br></br>
              <TextField
                label="Place of work"
                value={values.placeOfWork}
                onChange={handleChange('placeOfWork')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.placeOfWork.length > 0}
                helperText={errors.placeOfWork}
              />
              <br></br>
              <DatePicker
                  color="primary"
                  margin="normal"
                  label="Start Date"
                  value={values.startDate}
                  onChange={this.handleDateChange('startDate')}
                  isyearandmonth="true"
                  openTo="year"
                  disableFuture={true}
                  views={['year', 'month', 'day']}
                  format={"dd/MM/yyyy"}
                  variant="outlined"
                  fullWidth
                  error={errors.startDate.length > 0}
                  helperText={errors.startDate}
                />
                <br></br>
                <TextField
                label="Type of work"
                value={values.typeOfWork}
                onChange={handleChange('typeOfWork')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.typeOfWork.length > 0}
                helperText={errors.typeOfWork}
                />
                <br></br>
                <TextField
                label="Expected duration"
                value={values.expectedDuration}
                onChange={handleChange('expectedDuration')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.expectedDuration.length > 0}
                helperText={errors.expectedDuration.length > 0 ? errors.expectedDuration : "(In weeks or months)"}
                />
                <br></br>
                <TextField
                label="Services to be provided"
                value={values.servicesProvided}
                onChange={handleChange('servicesProvided')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.servicesProvided.length > 0}
                helperText={errors.servicesProvided.length > 0 ? errors.servicesProvided : "(on this assignment only)"}
                />
                <br></br>
                <TextField
                label="Assignment rate"
                value={values.assignmentRate}
                onChange={handleChange('assignmentRate')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.assignmentRate.length > 0}
                helperText={errors.assignmentRate}
                />
                <br></br>
              <div style={{position:"absolute", 
                              bottom:"35px",
                              right:"35px",
                              zIndex:99999}}>
       
                  <SubmitButton onClick={this.continue}>
                  </SubmitButton>
 
              </div>
              <div style={{height:"55px"}}>
                    {/* this is a spacer for the next button.. */}
                </div>
            </Paper>
          </React.Fragment>
        );
    }
}
export default withStyles(styles)(AssignmentDetails);