import React, {Component} from 'react';
import {Table, TableBody, TableCell, TableRow, TableHead, Paper, Typography, Button} from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faQuestion, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import config from '../../config/config';
import {Container, Row, Col} from 'react-bootstrap';

library.add(faCheck, faTimes, faQuestion, faChevronRight)



class Summary extends Component {
    continue = e => {
       this.props.nextStep(); 
    }
    back = e => {
      this.props.prevStep();
    }
    submitform = () => {
        axios.post(
            config.ApiPath + 'test/SubmitForm',
            { data:  this.props.values },
            function (error, response, body) {
                if (!error && response.statusCode === 200) {
                    console.log(body);
                }
            }
        );
    }

    render() {
        var values = this.props.values;
        return (
        <React.Fragment>
        <div>
        <div class="p-md-5">
              <h1>Summary</h1>
                    <Row className="py-2">
                      <Col xs={12} md={6}><Typography variant="h6" className="my-3 weightmansheader">Personal Details</Typography></Col>
                  </Row>
              <Paper elevation={3}> 
                <Container>
                    
                    <Row className="py-2">
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                            Name
                        </Col>
                        <Col xs={12} md={6}>
                            {values.name}
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                            Trade or Profession
                        </Col>
                        <Col xs={12} md={6}>
                            {values.tradeOrProfession}
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                            Email address
                        </Col>
                        <Col xs={12} md={6}>
                            {values.email}
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                            Telephone number
                        </Col>
                        <Col xs={12} md={6}>
                            {values.tel}
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                            Contract file
                        </Col>
                        <Col xs={12} md={6}>
                           {values.contractFileName}
                        </Col>
                    </Row>
                    <Row className="py-2">
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                            Address
                        </Col>
                        <Col xs={12} md={6}>
                           {values.addressLine1}<br/>
                           {values.addressLine2.length > 0 ? <>{values.addressLine2} <br/></>:null}
                           {values.town}<br/>
                           {values.county}<br/>
                           {values.postCode}<br/>
                        </Col>
                    </Row>
                    </Container>
                </Paper>
                <Row className="py-2">
                      <Col xs={12} md={6}><Typography variant="h6" className="my-3 weightmansheader">Company Details</Typography></Col>
                  </Row>
                <Paper elevation={3}>
                <Container>
                  <Row className="py-2">
                      <Col xs={12} md={6} style={{fontWeight: 600}}>
                        Company Name
                      </Col>
                      <Col xs={12} md={6}>
                        {values.companyName}
                      </Col>
                  </Row>
                  <Row className="py-2">
                      <Col xs={12} md={6} style={{fontWeight: 600}}>
                        Company Address
                      </Col>
                      <Col xs={12} md={6}>
                        {values.companyAddressLine1}<br/>
                        {values.companyAddressLine2.length > 0 ? <>{values.companyAddressLine2} <br/></>:null}
                        {values.companyTown}<br/>
                        {values.companyCounty}<br/>
                        {values.companyPostCode}<br/>
                      </Col>
                  </Row>
                  </Container>
                  </Paper>
                   <Row className="py-2">
                      <Col xs={12} md={6}><Typography variant="h6" className="my-3 weightmansheader">Assignment Details</Typography></Col>
                  </Row>
                  <Paper elevation={3}>
                      <Container>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Agency
                            </Col>
                            <Col xs={12} md={6}>
                                {values.agency}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Client
                            </Col>
                            <Col xs={12} md={6}>
                                {values.client}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Start Date
                            </Col>
                            <Col xs={12} md={6}>
                                {values.startDate.toLocaleDateString()}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Place of Work
                            </Col>
                            <Col xs={12} md={6}>
                                {values.placeOfWork}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Type of Work
                            </Col>
                            <Col xs={12} md={6}>
                                {values.typeOfWork}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Expected Duration
                            </Col>
                            <Col xs={12} md={6}>
                                {values.expectedDuration}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Services Provided
                            </Col>
                            <Col xs={12} md={6}>
                                {values.servicesProvided}
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={12} md={6} style={{fontWeight: 600}}>
                                Assignment Rate
                            </Col>
                            <Col xs={12} md={6}>
                                {values.assignmentRate}
                            </Col>
                        </Row>
                        </Container>
                        </Paper>
                        <Col xs={12} md={6} style={{fontWeight: 600}}>
                                <Typography variant="h6" className="my-3 weightmansheader">
                                    <br/>
                                    Initial Questionnaire
                                </Typography>
                            </Col>
                        <Paper elevation={3}>
                        <Container>
                        <Row className="py-2">
                            <Col xs={9} style={{fontWeight: 600}}>
                                Are you paying for the services of an individual via either a personal service company or another party?
                            </Col>
                            <Col xs={3} className="text-right">
                                {values.q1 == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q1 == "false" 
                                    ?   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q1 == "unknown" 
                                    ?   <FontAwesomeIcon icon="question" style={{color:"#5bc2e7", border:"3px solid #5bc2e7", borderRadius:"50%", padding: "4px", fontSize:"40px", height:"40px", width:"40px"}} />
                                    :   null
                                    }
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={9} style={{fontWeight: 600}}>
                                Are you able to confirm that the payments received by the individual are taxed via PAYE?
                            </Col>
                            <Col xs={3} className="text-right">
                            {values.q2 == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q2 == "false" 
                                    ?   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q2 == "unknown" 
                                    ?   <FontAwesomeIcon icon="question" style={{color:"#5bc2e7", border:"3px solid #5bc2e7", borderRadius:"50%", padding: "4px", fontSize:"40px", height:"40px", width:"40px"}} />
                                    :   null
                                    }
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={9} style={{fontWeight: 600}}>
                                Is the engagement within the construction industry and subject to HMRC’s CIS scheme?
                            </Col>
                            <Col xs={3} className="text-right">
                            {values.q3 == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q3 == "false" 
                                    ?   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q3 == "unknown" 
                                    ?   <FontAwesomeIcon icon="question" style={{color:"#5bc2e7", border:"3px solid #5bc2e7", borderRadius:"50%", padding: "4px", fontSize:"40px", height:"40px", width:"40px"}} />
                                    :   null
                                    }
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col xs={9} style={{fontWeight: 600}}>
                                Was the individual previously employed by the engaging organisation to carry out substantially the same tasks as they will carry out under the new arrangements?
                            </Col>
                            <Col xs={3} className="text-right">
                            {values.q4 == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q4 == "false" 
                                    ?   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   null
                                    }
                                    {values.q4 == "unknown" 
                                    ?   <FontAwesomeIcon icon="question" style={{color:"#5bc2e7", border:"3px solid #5bc2e7", borderRadius:"50%", padding: "4px", fontSize:"40px", height:"40px", width:"40px"}} />
                                    :   null
                                    }
                            </Col>
                        </Row>
                        </Container>
                        </Paper>
                        <Col xs={12}>
                                <Typography variant="h6" className="my-3">
                                    <br/>
                                    As a result of the initial questionnaire you were asked to complete an additional form so that you can be more accurately assessed.
                                    <br/><br/>
                                </Typography>
                            </Col>
                        <Paper elevation={3}>
                        <Container>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    Have you previously provided services to this Client immediately prior to this assignment?            
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.previouslyProvidedBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12}>
                                    {values.previouslyProvidedDetails}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    Have you previously worked for this Client as an employee?      
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.previouslyWorkedForClientAsEmployeeBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12}>
                                {values.previouslyWorkedForClientAsEmployeeDetails}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    Will there be a major change to your working arrangements on this assignment compared with your previous employment?     
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.previouslyWorkedForClientAsEmployeeMajorChangeBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12}>
                                {values.previouslyWorkedForClientAsEmployeeMajorChangeDetails}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am an expert with specialist skills, knowledge, or experience          
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.expertBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    Each day the Client decides what work I will do.        
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientDecidesWhatWork == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    The Client tells me how to do my work.        
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientDecidesHowWork == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    The Client gives me detailed instructions in relation to each task.            
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientDetailedInstructions == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    The Client requires me to follow a technical specification when performing each task.             
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientTechnicalSpecfication == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    The Client requires me to follow a standard procedures manual when carrying out each task.             
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientStandardProcedures == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    The Client decides where I do my work.            
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.clientDecidesWhereWork == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.clientDecidesWhereWorkDetail}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I work at the Client's premises due to security requirements.                
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientPremiseDueToSecurity == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I work at the Client's premises to preserve confidentiality.          
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientPremiseDueToConfidentiality == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    The Client tells me when to do the work (i.e. when to start the work and when to finish each day).    
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientDecidesWhenWork == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am contracted to work a set number of hours.         
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.contractedSetHours == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    The Client funds training for me to enable the work to be completed.           
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.clientFundsTraining == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    I am required to do the work for the Client myself.  Guidance; Consider all of the tasks involved in completing the work and whether you could engage a helper to do preparation work or administrative tasks.           
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.workMyselfBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.workMyselfDetails}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    I am free to have other people complete tasks at my discretion.  Guidance; Consider whether some tasks such as preparation work or administrative tasks can be completed by others.            
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.tasksByOthersBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.tasksByOthersDetail}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    I am free to have a suitably qualified or skilled replacement complete the work if I am sick or unable to do the work for some other reason.              
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.sickReplacement == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.sickReplacementDetails}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I receive a regular wage, salary or pay, even if there is no work available            
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.regularPay == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I receive or am entitled to receive paid annual leave.              
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.annualLeave == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                I have to ask permission to take time off rather than just telling the client out of courtesy.           
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.permissionForTimeOff == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                I receive or am entitled to receive sick pay.       
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.sickPay == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I receive or am entitled to receive paid parental leave (e.g maternity pay, paternity pay, shared parental leave pay) from the Client.          
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.parentalLeave == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am entitled to a pension as part of my terms with the Client.             
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.pension == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am entitled to overtime pay.             
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.overtimePay == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am entitled to bonus payments             
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.bonusPayments == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am entitled to a fuel card.     
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.fuelCard == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    I am entitled to receive or have access to other Client benefits.  Guidance; such as a company gym membership, childcare vouchers, private healthcare.        
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.otherBenefitsBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.otherBenefitsDetail}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I attend social functions organised and funded by the Client such as team-building events or Christmas parties.    
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.fundedSocialEvents == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I invoice the client for the work I do.            
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.invoiceClient == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    Payment for the work goes into a bank account which is separate from my personal bank account.           
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.paidIntoSeperateBank == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I pay for professional advice and accounting.             
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.payForProfessionalAdvice == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am responsible for putting right any unsatisfactory work at my own expense and in my own time.         
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.responsibleForCorrectingUnsatisfactoryWork == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    Professional indemnity insurance and/or other insurance will be held for the duration of the assignment.            
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.insurance == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I can make a profit or loss on assignments.       
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.profitOrLoss == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    I provide the main tools and equipment needed to carry out my work.         
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.provideToolsEquipmentBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                                
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.provideToolsEquipmentDetail}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    I am obliged to accept work which the Client offers to me.           
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.obligedToAcceptWork == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{borderBottom:"none", fontWeight:700}}>
                                    I am free to earn income from sources other than the service under examination in this assessment.           
                                </Col>
                                <Col className="text-right" xs={3} style={{borderBottom:"none"}}>
                                    {values.freeToEarnOtherIncomeBool == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={12} md={6}>
                                    {values.freeToEarnOtherIncomeDetail}
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    My services will be advertised (e.g. via a website) to encourage income from other sources.           
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.servicesAdvertised == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col xs={9} style={{fontWeight:700}}>
                                    A business plan will contain a forecast of the income I expect to receive over the next twelve months.         
                                </Col>
                                <Col xs={3} className="text-right">
                                    {values.incomeForecast == "true" 
                                    ?   <FontAwesomeIcon icon="check" style={{color:"#b5bd00", border:"3px solid #b5bd00", borderRadius:"50%", padding: "4px", fontSize:"40px"}} />
                                    :   <FontAwesomeIcon icon="times" style={{color:"#ba0c2f", border:"3px solid #ba0c2f", borderRadius:"50%", padding: "2px 0px 2px 0px", fontSize:"80px", height:"40px", width:"40px"}}/>
                                    }
                                </Col>
                            </Row>
              </Container>
              </Paper>
              </div>
              </div>
              <div>
                  <Paper elevation={3} className="p-3 p-md-5 my-3" style={{position:"relative"}}>
                  When you send this information it will be received by Weightmans and used to assess and advise on your status under IR35/off payroll working. This information and advice will be shared with you and also with any end user, employment agency or employment business through which your services are being provided but only where that end user, employment agency or employment business is paying for this advice.  We may also need to use the information to ensure that we have no conflict in providing advice to you on this issue (particularly ensuring that Weightmans is not elsewhere advising the end user client).  
Weightmans is a data processor under GDPR and a copy of our privacy policy is available on our website. <br/><br/><br/>

<Typography variant="h6" className="pb-5 pb-md-2">If you are happy with the above and your details are accurate, please click "submit".</Typography>

<div style={{position:"absolute", 
                              bottom:"35px",
                              right:"35px",
                              backgroundColor:"rgba(0,0,0,0.3)",
                              zIndex:99999}}>
                  <Button variant="contained" color="primary" className="weightmansbutton" onClick={this.props.nextStep}>Submit <FontAwesomeIcon icon="chevron-right" className="ml-2"/></Button>
                              
                              </div>
                  </Paper>

                  

              </div>
          </React.Fragment>  
        );
    }
}
export default (Summary);