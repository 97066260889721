import React, {Component} from 'react'
//import TextField from '@material-ui/core/TextField';
import TextField from '../controls/CustomizedTextbox';
import { Paper, Typography, Button, Tooltip} from '@material-ui/core'
import SubmitButton from '../controls/SubmitButton'; 
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import {Container, Row, Col, ProgressBar} from 'react-bootstrap'
import config from '../../config/config';
var postcodeValidator = require('postcode-validator');

var validator = require("email-validator");

library.add(faFile)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    backgroundColor: 'black',
  },  
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class ContractorContactDetails extends Component 
{
    constructor(props) {
      super(props)
      this.state = { 
                     selectedFile: null,
                     fileUploaded: false,
                     progressPercentage: 0,
                     showValidation: false
                   }
      }
    continue = e => {
      var errors = this.validate();
      var errorLength = 0
      for (var property in errors)
      {
          errorLength += errors[property].length;
      }

      if (errorLength > 0)
      {
          this.setState({showValidation: true})
      }
      else
      {
          this.props.nextStep(); 
      }

    }
    

    validate = () => {
      const {values}  = this.props;
      var errors = {
        name: '',
        tradeOrProfession: '',
        email: '',
        tel: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        county: '',
        postcode: ''
      }

      if (values.name.length < 1)
      {
        errors.name = "Cannot be blank"
      }

      if (values.tradeOrProfession.length < 1)
      {
        errors.tradeOrProfession = "Cannot be blank";
      }

      if (!validator.validate(values.email))
      {
          errors.email = "Invalid email address";
      }

      if (values.addressLine1.length < 1)
      {
        errors.addressLine1 = "Cannot be blank";
      }

      if (values.town.length < 1)
      {
        errors.town = "Cannot be blank";
      }

      if (values.county.length < 1)
      {
        errors.county = "Cannot be blank";
      }

      if (values.county.length < 1)
      {
        errors.county = "Cannot be blank";
      }

      if (values.tel.length < 1)
      {
        errors.tel = "Cannot be blank";
      }

      if (!postcodeValidator.validate(values.postCode, 'UK'))
      {
        errors.postcode = "Invalid Postcode";
      }

      return errors;
    }

    handleDateChange = (field) => date => {
      this.props.handleChangeAlt(field, date);
    }

    handleFileChange = (event) => {
      this.setState({selectedFile: event.target.files[0]})
      this.setState({fileUploaded: false, progressPercentage: 0})
    }
    uploadFile = () => {
      const data = new FormData();
      data.append('file', this.state.selectedFile);
      const axiosConfig = {
          onUploadProgress: progressEvent => {
            var progressPercentage =  Math.floor(progressEvent.loaded / this.state.selectedFile.size * 100);
            if (progressPercentage > 100)
            {
              progressPercentage = 100;
            }

            this.setState({progressPercentage})

          }
        }
        axios.post(config.ApiPath + 'test/upload', data)
          .then(res => { // then print response status
            if (res.statusText == "OK") {
              this.setState({fileUploaded: true, })
              //alert(res.data.filename)
            }
          })
    }

    render() {

      var errors = this.validate();
      if (!this.state.showValidation){
          //clears all errors for display if they've not clicked the button yet
          for (var property in errors)
          {
              errors[property] = '';
          }
      }

      console.log('rendering contractorcontactdetails');
      const { classes } = this.props;
        const {values , handleChange, handleChangeAlt } = this.props;

        let showSubmitButton = values.name != undefined && values.name != '' && values.tradeOrProfession != undefined && values.tradeOrProfession != '' && values.email != undefined && values.email != '' && values.tel != undefined && values.tel != '';
        let currentDate = new Date();

        return (
    
          <React.Fragment>
            <div className="mx-auto text-left" style={{maxWidth:"450px"}}>
            <Paper elevation={3} className="p-4 mt-3 pb-5" style={{position:"relative", maxWidth:"450px"}}>
              <Typography variant="h6" className="mb-3 weightmansheader">
                Contractor contact details
              </Typography>
              <TextField
                label="Contractor name"
                value={values.name}
                onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
                fullWidth
                error = {errors.name.length > 1}
                helperText = {errors.name}
              />
              <br></br>
              <TextField
                label="Your trade or profession"
                value={values.tradeOrProfession}
                onChange={handleChange('tradeOrProfession')}
                margin="normal"
                variant="outlined"
                fullWidth
                error = {errors.tradeOrProfession.length > 1}
                helperText = {errors.tradeOrProfession}
              />
              <br></br>
               <TextField
                label="Contractor email"
                value={values.email}
                onChange={handleChange('email')}
                margin="normal"
                variant="outlined"
                fullWidth
                error = {errors.email.length > 1}
                helperText = {errors.email}
              />
              <br></br>
               <TextField
                label="Contact number(s)."
                value={values.tel}
                onChange={handleChange('tel')}
                margin="normal"
                variant="outlined"
                multiline
                fullWidth
                error = {errors.tel.length > 1}
                helperText = {errors.tel}
              />

              <br>
              </br>
              {/* <TextField
                label="Address"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.address}
                onChange={handleChange('address')}
                error = {errors.address.length > 1}
                helperText = {errors.address}
                />
              <br></br> */}
              <TextField
                label="Address Line 1"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.addressLine1}
                onChange={handleChange('addressLine1')}
                error = {errors.addressLine1.length > 1}
                helperText = {errors.addressLine1}
                />
                 <br></br>
              <TextField
                label="Address Line 2"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.addressLine2}
                onChange={handleChange('addressLine2')}
                error = {errors.addressLine2.length > 1}
                helperText = {errors.addressLine2}
                />
              <br></br>
              <TextField
                label="Town"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.town}
                onChange={handleChange('town')}
                error = {errors.town.length > 1}
                helperText = {errors.town}
                />
                <br></br>
              <TextField
                label="County"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.county}
                onChange={handleChange('county')}
                error = {errors.county.length > 1}
                helperText = {errors.county}
                />
              <br></br>
              <TextField
                label="Postcode"
                multiline
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.postCode}
                onChange={handleChange('postCode')}
                error = {errors.postcode.length > 1}
                helperText = {errors.postcode}
                />
              <br></br>
              <br></br>
          
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                />




              <div style={{position:"absolute", 
                              bottom:"25px",
                              right:"35px",
                              zIndex:99999}}>

                  <SubmitButton onClick={this.continue}>
                  </SubmitButton>
              </div>

            </Paper>
            </div>

            
          </React.Fragment>
        );
    }
}
export default withStyles(styles)(ContractorContactDetails);