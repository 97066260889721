import React, {Component} from 'react'
//import TextField from '@material-ui/core/TextField';
import TextField from '../controls/CustomizedTextbox';
import { Paper, Typography, Button, RadioGroup, FormControlLabel, Radio} from '@material-ui/core'
import SubmitButton from '../controls/SubmitButton';
import BackButton from '../controls/BackButton';
import {ProgressBar} from 'react-bootstrap';
class QuestionnaireQuestion extends Component {
    constructor(props){
      super(props);
      this.state={
        showValidation: false
      }
 
    }
    continue = e => {
      var valid = false;
      const {value, detailValue, detailText, handleChange, detailFieldName, detailNoText} = this.props;

      if (value === "true")
      {
        if (detailText === '' || detailText === undefined)
        {
          valid = true;
        }
        else
        {
          if (detailValue !== '' && detailValue !== undefined)
          {
            valid = true;
          }
        }
      }
      else if (value === "false")
      {
        if (detailNoText === '' || detailNoText === undefined)
        {
          valid = true;
        }
        else
        {
          if (detailValue !== '' && detailValue !== undefined)
          {
            valid = true;
          }
        }
        
      }
      if (valid)
      {
        this.setState({showValidation: false}); //shouldn't really be needing this but the new questions are using the previous question's state. this fixes that.
                                                //https://stackoverflow.com/questions/29074690/react-why-components-constructor-is-called-only-once
        this.props.nextStep();
      }
      else
      {
        this.setState({showValidation: true});
      }
    }
    back = e => {
      this.props.prevStep();
    }
    render() {
      console.log('rendering Questionnaire1');
        const {value, detailValue, detailText, handleChange, detailFieldName, detailNoText} = this.props;

        let  valid = false;
        var radioUnfilled = false;
        let textboxHint = "";
        let helperText = 'Cannot be blank';
        if (value === "true")
        {
          textboxHint = detailText;
          if (detailText === '' || detailText === undefined)
          {
             valid = true;
             helperText = '';
          }
          else
          {
            if (detailValue !== '' && detailValue !== undefined)
            {
               valid = true;
               helperText = '';
               
            }
          }
     
        }
        else if (value === "false")
        {
          textboxHint = detailNoText;
          if (detailNoText === '' || detailNoText === undefined)
          {
             valid = true;
             helperText = '';

          }
          else
          {
            if (detailValue !== '' && detailValue !== undefined)
            {
               valid = true;
               helperText = '';

            }
          }
     
        }
        else { 
          radioUnfilled = true;
          helperText = '';
 
        }
        if (!this.state.showValidation)
        {
          radioUnfilled = false;
          helperText = '';
        }
 
        let showMoreDetail = ((value === 'true') && detailText) || ((value ==='false') && detailNoText);
        let currentDate = new Date();
     
        const percentage = Math.round((this.props.questionNum / 40)* 100);

        return (
          <React.Fragment>
            <ProgressBar now={percentage} striped></ProgressBar>
            <Paper elevation={3} className="p-3 p-md-5 mt-3 mx-auto " style={{position:"relative"}}>
            <div style={{position:"absolute",
                        right: "20px",
                        top:"20px",
                        height: "30px"}}>
                        {/* <CircularProgressbar
              percentage={percentage}
              text={`${percentage}%`}
            /> */}
                        </div>
            
            <Typography variant="h6" className="mb-4 weightmansgrey">
              {this.props.questionText}
            </Typography>
                <RadioGroup 
                  aria-label=""
                  name="rdnoYesNo"
                  value={this.props.value}
                  onChange={this.props.handleChange(this.props.fieldName)}
                  error={radioUnfilled}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Yes"
                    labelPlacement="end"
                    className="pr-3"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                    labelPlacement="end"
                    className=""
                  />
              </RadioGroup>
              <br></br>
              <div class="d-none d-md-block">
                {/* THIS A THING VISIBLE ON DESKTOP */}
              { showMoreDetail? 
                <TextField
                  label={textboxHint}
                  value={detailValue}
                  onChange={handleChange(detailFieldName)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={helperText.length > 0}
                  helperText={helperText}
                />
                :
                null
              }
              </div>
              <div class="d-block d-md-none">
                {/* THIS IS A THING VISIBLE ON MOBILE */}
                <p className="fs-12">{textboxHint}</p>
              { showMoreDetail? 
                <TextField
                label={"Additional info"}
                value={detailValue}
                onChange={handleChange(detailFieldName)}
                margin="normal"
                variant="outlined"
                fullWidth
                error={helperText.length > 0}
                helperText={helperText}
                />
                :
                null
              }
              </div>
              
              
              <div style={{position:"absolute", 
                              bottom:"35px",
                              right:"35px",
                              zIndex:99999}}>
      
                <React.Fragment>
                  <SubmitButton onClick={this.continue}>
                  </SubmitButton>
                </React.Fragment>
                  
          
              </div>
              <div style={{position:"absolute",
                           bottom:"35px",
                           left:"35px",
                           }}>
                  <BackButton onClick={this.back} buttonText="Back">
                  </BackButton>

               </div>
                <div style={{height:"60px"}}>
                    {/* this is a spacer for the next button.. */}
                </div>
            </Paper>
          </React.Fragment>
        );
    }
}
export default (QuestionnaireQuestion);