import React, {Component} from 'react'
//import TextField from '@material-ui/core/TextField';
import TextField from '../controls/CustomizedTextbox';
import { Paper, Typography, Button, RadioGroup, FormControlLabel, Radio} from '@material-ui/core'
import SubmitButton from '../controls/SubmitButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, InlineDatePicker } from 'material-ui-pickers';
var postcodeValidator = require('postcode-validator');

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class Step1 extends Component {
    constructor(props){
      super(props)
      this.state = {
        showValidation: false
      }
    }
    continue = e => {
      var errors = this.validate();
      var errorLength = 0
      for (var property in errors)
      {
        errorLength += errors[property].length;
      }

      if (errorLength > 0)
      {
        this.setState({showValidation: true})
      }
      else
      {
        this.props.nextStep(); 
      }
    }
    handleDateChange = (field) => date => {
      this.props.handleChangeAlt(field, date);
    };

    validate = () => {
      var errors = {
        companyName: '',
        companyAddress: '',
        companyAddressLine1: '',
        companyAddressLine2: '',
        companyTown: '',
        companyCounty: '',
        companyPostCode: '',
      }

      var {values} = this.props;
      if (values.companyName.length < 1) {
        errors.companyName = "Cannot be blank"
      }
      if (values.companyAddressLine1.length < 1)
      {
        errors.companyAddressLine1 = "Cannot be blank"
      }
      if (values.companyTown.length < 1)
      {
        errors.companyTown = "Cannot be blank"
      }
      if (values.companyCounty.length < 1)
      {
        errors.companyCounty = "Cannot be blank"
      }
      if (!postcodeValidator.validate(values.companyPostCode, 'UK'))
      {
        errors.companyPostCode = "Invalid Postcode"
      }
      return errors;

    }

    render() {
      console.log('rendering step2');
      const { classes } = this.props;
        const {values , handleChange, handleChangeAlt } = this.props;
        var errors = this.validate();
        if (!this.state.showValidation){
          //clears all errors for display if they've not clicked the button yet
            for (var property in errors)
            {
                errors[property] = '';
            }
        }
        let showSubmitButton = values.name != undefined && values.name != '' && values.tradeOrProfession != undefined && values.tradeOrProfession != '' && values.email != undefined && values.email != '' && values.tel != undefined && values.tel != '';

        let currentDate = new Date();

        return (
          <React.Fragment>
            <Paper elevation={3} className="p-4 mt-3 mx-auto pb-4" style={{position:"relative", maxWidth: "400px"}}>
              <Typography variant="h6" className="mb-3 weightmansheader">
                Company details
              </Typography>
              <TextField
                label="Company name"
                value={values.companyName}
                onChange={handleChange('companyName')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.companyName.length > 0}
                helperText={errors.companyName}
              />
               <br></br>
               <TextField
                multiline
                label="Address Line 1"
                value={values.companyAddressLine1}
                onChange={handleChange('companyAddressLine1')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.companyAddressLine1.length > 0}
                helperText={errors.companyAddressLine1}
              /> 
              <br></br>
               <TextField
                multiline
                label="Address Line 2"
                value={values.companyAddressLine2}
                onChange={handleChange('companyAddressLine2')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.companyAddressLine2.length > 0}
                helperText={errors.companyAddressLine2}
              /> 
              <br></br>
               <TextField
                multiline
                label="Town"
                value={values.companyTown}
                onChange={handleChange('companyTown')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.companyTown.length > 0}
                helperText={errors.companyTown}
              /> 
              <br></br>
               <TextField
                multiline
                label="County"
                value={values.companyCounty}
                onChange={handleChange('companyCounty')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.companyCounty.length > 0}
                helperText={errors.companyCounty}
              /> 
              <br></br>
               <TextField
                multiline
                label="PostCode"
                value={values.companyPostCode}
                onChange={handleChange('companyPostCode')}
                margin="normal"
                variant="outlined"
                fullWidth
                error={errors.companyPostCode.length > 0}
                helperText={errors.companyPostCode}
              /> 
             
             
              <div style={{position:"absolute", 
                              bottom:"35px",
                              right:"35px",
                              zIndex:99999}}>

                  <SubmitButton onClick={this.continue}>
                  </SubmitButton>

              </div>
              <div style={{height:"60px"}}>
                    {/* this is a spacer for the next button.. */}
                </div>
            </Paper>
          </React.Fragment>
        );
    }
}
export default withStyles(styles)(Step1);