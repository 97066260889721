import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
 
library.add(faChevronLeft)

const styles = theme => ({

});
class BackButton extends React.Component {
    
    render(){
        var ButtonText = "Next";
        if (this.props.buttonText !== undefined)
        {
            ButtonText = this.props.buttonText;
        }
        return (
            <Button variant="contained" color="primary" className="weightmansbutton" onClick={() => this.props.onClick(1)}>
               <FontAwesomeIcon icon="chevron-left" className="mr-2"/>
               {ButtonText} 
            </Button>
        )
    }
}

export default withStyles(styles)(BackButton);