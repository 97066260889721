import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
 
library.add(faChevronRight)

const styles = theme => ({

});
class SubmitButton extends React.Component {
    render(){
        var ButtonText = "next";
        if (this.props.buttonText !== undefined)
        {
            ButtonText = this.props.buttonText;
        }
        return (
            <Button ref="btn" variant="contained" color="primary" className="weightmansbutton" onClick={() =>  this.props.onClick(1) }>
               {ButtonText} <FontAwesomeIcon icon="chevron-right" className="ml-2"/>
            </Button>
        )
    }
}

export default withStyles(styles)(SubmitButton);