import uatConfig from './config-uat.json';
import devConfig from './config-dev.json';
import liveConfig from './config-live.json';
import buildConfig from './config.json';

var activeConfig = undefined;


switch (process.env.NODE_ENV)
{
    case "development":
        activeConfig = devConfig;
        //activeConfig = uatConfig;
        //activeConfig = liveConfig;
        break;
    case "production":
        activeConfig = buildConfig
        break;
    default:
        alert("invalid NODE_ENV set");
        break;
}
export default activeConfig;