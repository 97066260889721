import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Col, Row, Container} from 'react-bootstrap';
const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class RadioButtonsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }
  handleChange = event => {
    this.setState({ value: event.target.value });
    this.props.changeEvent();
    console.log(this.props);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
   
        <FormControl component="fieldset" className={classes.formControl}>
        <Row>
          <RadioGroup 
              aria-label=""
              name="rdnoYesNo"
              className={classes.group}
              value={this.state.value}
              onChange={this.handleChange}
            
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
                labelPlacement="end"
                className="pr-3"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
                labelPlacement="end"
                className=""
              />
              <FormControlLabel
                value="unknown"
                control={<Radio color="primary" />}
                label="I don't know"
                labelPlacement="end"
                className=""
              />
            </RadioGroup>
        </Row>
        </FormControl>

      </div>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonsGroup);