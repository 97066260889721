import React, {Component} from 'react'
import { Paper, Typography, Button} from '@material-ui/core'
import SubmitButton from '../controls/SubmitButton'; 
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import {ProgressBar} from 'react-bootstrap'
import config from "../../config/config.js";

library.add(faFile);

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class ContractorContactDetails extends Component 
{
    constructor(props) {
      super(props)
      this.state = 
      { 
        selectedFile: null,
        invalidFile: false,
        fileUploaded: false,
        progressPercentage: 0,
        showValidation: false,
        errorMessage: '',
      }
    }
    continue = e => {
      
      if (this.state.fileUploaded)
      {
        this.props.nextStep(); 
      }
      else
      {
        this.setState({showValidation: true})
      }
    }

    handleDateChange = (field) => date => {
      this.props.handleChangeAlt(field, date);
    }

    handleFileChange = (event) => {
      this.setState({selectedFile: event.target.files[0]})
      this.setState({fileUploaded: false, progressPercentage: 0})
      var fileExtension = event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length - 1];
      var fileName = event.target.files[0].name;
      this.props.handleChange2('contractFileType', fileExtension)
      this.props.handleChange2('contractFileName', fileName);
    }
    uploadFile = () => {
      const data = new FormData();
      var extension = this.state.selectedFile.name.split('.')[this.state.selectedFile.name.split('.').length - 1];
      var extension = "txt";
      var extensionAllowed = false;
      switch (extension)
      {
        case 'pdf':
        case 'doc':
        case 'docx': 
        case 'rtf': 
        case 'odt': 
        case 'txt': 
        case 'msg':
          this.setState({invalidFile: false})
          break;
        default: 
          this.setState({invalidFile: true})
          return;
      }
      data.append('files', this.state.selectedFile);
      data.append('email', this.props.email);

      const axiosConfig = {
        onUploadProgress: progressEvent => {
          var progressPercentage =  Math.floor(progressEvent.loaded / this.state.selectedFile.size * 100);
          if (progressPercentage > 100)
          {
            progressPercentage = 100;
          }
          this.setState({progressPercentage})
        },
        headers: {
          "Content-Type": "multi-part/form-data"
        }
    }
   
      axios.post(config.ApiPath + 'test/UploadFile', data, axiosConfig)
            .then((result) => {
                let message = "Success!"
          
                if (!result.data.success) {
                    message = result.data.message;
                    this.setState({errorMessage: result.data.errorMessage})
                }
                else{
                  this.setState({fileUploaded: true});
                }
            })
            .catch((ex) => {
                console.error(ex);
            });
    }

    render() {
      console.log('rendering contractorcontactdetails');
      const { classes } = this.props;
        const {values , handleChange, handleChangeAlt } = this.props;

        let showSubmitButton = this.state.fileUploaded;
        let showUploadButton = !this.state.fileUploaded && this.state.selectedFile !== null;
        let currentDate = new Date();

        return (
    
          <React.Fragment>
            <div className="mx-auto text-left" style={{maxWidth:"450px"}}>
              <Paper elevation={3} className="p-4 mt-3 pb-4" style={{position:"relative", maxWidth:"450px", height:"505px"}}>
                <Typography variant="h6" className="mb-3 weightmansheader">
                  Upload Contract
                </Typography>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />

                  {this.state.fileName != "" 
                    ? <div>
                        <Typography variant="h6">
                        {this.state.fileName}
                        </Typography>
                        </div>
                        
                    : null
                  }
                  {this.state.selectedFile == null 
                  ? <React.Fragment>
                    <div className="mx-auto py-3" style={{textAlign:"center"}}>
                      <p>Please upload your contract for your assignment, for assessment by Weightmans LLP.</p>
                    </div>
                    <div className="mx-auto py-3" style={{textAlign:"center"}}>
                      <Button
                        variant="contained" color="primary" className="weightmansbutton"
                        component="label"
                      >
                        Select Contract
                        <input type="file" name="sampleFile" onChange={this.handleFileChange} style={{display:"none"}}/>
                        <br />

                      </Button>
                    </div>
                    </React.Fragment>

                  : <div className="mx-auto" style={{textAlign:"center"}}>
                      <FontAwesomeIcon icon="file" style={{color:"#178F8E", fontSize: "40px"}}/>
                      <p>{this.state.selectedFile.name}</p>
                      <Button
                        variant="contained" color="primary" className="weightmansbutton mb-3"
                        component="label"
                      >
                        Change File
                        <input type="file" name="sampleFile" onChange={this.handleFileChange} style={{display:"none"}}/>
                        <br />
                      </Button>
                      <br/><br/><br/>
                      Uploaded
                      <ProgressBar striped variant="blue" now={this.state.progressPercentage} key={1} />
                      {this.state.progressPercentage}%
                      <br/>
                    </div>
                  }
                  {this.state.invalidFile
                  ? <p className="text-weightmansred"><strong>Invalid file type. Accepted: pdf,doc,docx,rtf,txt,odt,msg. Please try again</strong></p>
                  : null
                  }
                  {this.state.errorMessage.length > 0
                  ? <p className="text-weightmansred"><strong>{this.state.errorMessage}</strong></p>
                  : null
                  }
                  {showUploadButton == true
                  ? <div className="mx-auto py-3" style={{textAlign:"center"}}>
                        <Button
                        variant="contained" color="primary" className="weightmansbutton"
                        component="label"
                        onClick={this.uploadFile}
                        >
                          Upload
                        </Button> 
                    </div>
                  : null 
                  }
                  { this.state.showValidation && !this.state.fileUploaded 
                  ? <p class="text-weightmansred text-center"><strong>Please upload your contract</strong></p>
                  : null
                  }
                <div style={{position:"absolute", 
                                bottom:"25px",
                                right:"35px",
                                zIndex:99999}}>
    
                 <SubmitButton onClick={this.continue}></SubmitButton>
                </div>

              </Paper>
            </div>

            
          </React.Fragment>
        );
    }
}
export default withStyles(styles)(ContractorContactDetails);