
import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import {Row, Col} from 'react-bootstrap';

const styles = theme => ({
    bgBlack: {
      backgroundColor:"#178F8E"
    }
  });

  class NavBar extends Component {
    render(){
        const { classes } = this.props;
        return(
            <div>
            <AppBar position="static" className={classes.bgBlack}>
                <Toolbar>
                    <Typography variant="title" color="inherit">
                       
                            <a href="http://www.weightmans.com/">

                                <img style={{float:"left", height:"80px"}} alt="logo" src={require("../assets/images/weightmanslogo.png")} className="p-3 d-none d-md-block"></img>
                                <img style={{float:"left", width:"80px", marginTop: "16px"}} alt="logo" src={require("../assets/images/weightmanslogo.png")} className="d-block d-md-none"></img>
                            </a>
                                
                                <span className="p-2 d-inline-block d-md-none" style={{lineHeight:"56px"}}>
                                    IR35 Status Tool
                                </span>
                            
                                <span className="p-2 d-none d-md-inline-block" style={{lineHeight:"64px"}}>
                                    IR35 Status Checking Tool
                                </span>
                     
                    </Typography>
                </Toolbar>
            </AppBar>
            </div>
        )
    }
   
}
export default withStyles(styles)(NavBar);