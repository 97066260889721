import React, {Component} from 'react'
import { Paper, Typography} from '@material-ui/core'
import RadioYesNo from '../controls/RadioYesNo';
import SubmitButton from '../controls/SubmitButton';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
class Q1 extends Component {
    continue = e => {
       this.props.nextStep(); 
    }

    render() {
        const {values , handleChange } = this.props;
        let showSubmitButton = values.q1 != undefined && values.q1 != '';
        return (
            <Paper elevation={3} className="p-5 mt-3" style={{position:"relative"}}>
            <Typography variant="h6" className="mb-4">
            Are you paying for the services of an individual via either a personal service company or another party?
            </Typography>
            <RadioGroup 
              aria-label=""
              name="rdnoYesNo"
              value={values.q1}
              onChange={this.props.handleChange('q1')}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
                labelPlacement="end"
                className="pr-3"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
                labelPlacement="end"
                className=""
              />
              <FormControlLabel
                value="unknown"
                control={<Radio color="primary" />}
                label="I don't know"
                labelPlacement="end"
                className=""
              />
            </RadioGroup>
            <div style={{position:"absolute", 
                
                            bottom:"35px",
                            right:"35px",
                            
                            zIndex:99999}}>
            { showSubmitButton ? (
                <SubmitButton onClick={this.continue}>
                </SubmitButton>
                ) : null}
            </div>

            </Paper>
        );
    }
}
export default (Q1);