import React, {Component} from 'react'
import { Paper, Button } from '@material-ui/core'
import TextField from '../controls/CustomizedTextbox'
var validator = require("email-validator");

class Introduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showValidation: false
        }
        console.log(this.props)
    }

    validate = () => {

        var errors = {
            name: '',
            email: ''
        }
        if (!this.props.contractorName.length > 0) {
            errors.name = "Cannot be blank";
        }

        if (!validator.validate(this.props.personalEmail))
        {
            errors.email = "Invalid email address";
        }

        return errors;
    }
    
    continue = e => {
        var errors = this.validate();
        var errorLength = 0
        for (var property in errors)
        {
            errorLength += errors[property].length;
        }

        if (errorLength > 0)
        {
            this.setState({showValidation: true})
        }
        else
        {
            this.props.onConfirm(); 
        }
    }

    render() {
        var errors = this.validate();
        if (!this.state.showValidation){
            //clears all errors for display if they've not clicked the button yet
            for (var property in errors)
            {
                errors[property] = '';
            }
        }
        return(
            <Paper elevation={3} className="p-5" style={{position:"relative"}}>
                <h1 className="mb-4">Introduction</h1>
                <p className="mb-4">
                    Employment Status tests are far from straightforward and require careful consideration of a variety of factors relevant to the relationship. Sometimes though because of a particular fact or circumstances, the position is clear. This short initial questionnaire will provide an indication about whether or not a relationship meets the requirements of employment status for off payroll working purposes. 
                </p>
                <p className="mb-4">
                    Please provide your name and email address so we can send you a copy of your results.
                </p>
                <TextField
                label="Name"
                value={this.props.contractorName}
                onChange={this.props.handleChange('name')}
                margin="normal"
                variant="outlined"
                fullWidth
                className="mb-4"
                error={errors.name.length > 0}
                helperText={errors.name}
              />
              <br></br>
              
              <TextField
                label="Email Address"
                value={this.props.personalEmail}
                onChange={this.props.handleChange('personalEmail')}
                margin="normal"
                variant="outlined"
                fullWidth
                className="mb-4"
                error={errors.email.length > 0}
                helperText={errors.email}
              />
              <br></br><br></br> 
                <div style={{position:"absolute", 
                    bottom:"35px",
                    right:"35px",
                    zIndex:99999}}>
                    {/* {(this.props.personalEmail !== '' && this.props.contractorName !== '') ?  */}
                    <Button onClick={this.continue}
                    variant="contained" color="primary" className="weightmansbutton">Continue</Button>
                    {/* : null */}
                    {/* } */}
                     
                </div>
               
                
            </Paper>
        )
    }
}
export default (Introduction);