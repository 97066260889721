import React, {Component} from 'react'

import QuestionnaireQuestion from '../Questback/QuestionnaireQuestion';
class AssignmentQuestionnaire extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,     
    }
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({step: step + 1 });
  }
  prevStep = () => {
    const { step } = this.state;
    if (this.state.step === 4)
    {
      this.setState({step: step - 2}); //horrible hacky workaround for the 1 odd question flow.
    }
    else
    {
      this.setState({step: step - 1 });
    }
  }
  
  // Handlefields change
  handleChange = (input) => e => {
    console.log('questbackformchange' + input + '-' + e.target.value)
    //this.setState({[input]: e.target.value});
    this.props.handleChangeAlt(input, e.target.value);
  }

  handleSubmit = async () => {
  
    const response = await fetch('/api/files/testPost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state),
    });
    const body = await response.text();
  };

  render() {
    
    console.log('rendering assignment questionnaire')
    var { 
      step,
      q1,
      q2,
      q3,
      q4,
      name,
      tradeOrProfession,
      email,
      tel,
      address,
      contractFileType,
      companyName,
      companyAddress,
      agency,
      client,
      startDate,
      placeOfWork,
      typeOfWork,
      expectedDuration,
      servicesProvided,
      assignmentRate,
      previouslyProvidedBool,
      previouslyProvidedDetails,
      previouslyWorkedForClientAsEmployeeBool,
      previouslyWorkedForClientAsEmployeeDetails,
      previouslyWorkedForClientAsEmployeeMajorChangeBool,
      previouslyWorkedForClientAsEmployeeMajorChangeDetails,
      expertBool,
      clientDecidesWhatWork,
      clientDecidesHowWork,
      clientDetailedInstructions,
      clientTechnicalSpecfication,
      clientStandardProcedures,
      clientDecidesWhereWork,
      clientDecidesWhereWorkDetail,
      clientPremiseDueToSecurity,
      clientPremiseDueToConfidentiality,
      clientDecidesWhenWork,
      contractedSetHours,
      clientFundsTraining,
      workMyselfBool,
      workMyselfDetails,
      tasksByOthersBool,
      tasksByOthersDetail,
      sickReplacement,
      sickReplacementDetails,
      regularPay,
      annualLeave,
      permissionForTimeOff,
      sickPay,
      parentalLeave,
      pension,
      overtimePay,
      bonusPayments,
      fuelCard,
      otherBenefitsBool,
      otherBenefitsDetail,
      fundedSocialEvents,
      invoiceClient,
      paidIntoSeperateBank,
      payForProfessionalAdvice,
      responsibleForCorrectingUnsatisfactoryWork,
      insurance,
      profitOrLoss,
      provideToolsEquipmentBool,
      provideToolsEquipmentDetail,
      obligedToAcceptWork,
      freeToEarnOtherIncomeBool,
      freeToEarnOtherIncomeDetail,
      servicesAdvertised,
      incomeForecast
    } = this.props.values;
    var values = { 
      step,
      q1,
      q2,
      q3,
      q4,
      name,
      tradeOrProfession,
      email,
      tel,
      address,
      contractFileType,
      companyName,
      companyAddress,
      agency,
      client,
      startDate,
      placeOfWork,
      typeOfWork,
      expectedDuration,
      servicesProvided,
      assignmentRate,
      previouslyProvidedBool,
      previouslyProvidedDetails,
      previouslyWorkedForClientAsEmployeeBool,
      previouslyWorkedForClientAsEmployeeDetails,
      previouslyWorkedForClientAsEmployeeMajorChangeBool,
      previouslyWorkedForClientAsEmployeeMajorChangeDetails,
      expertBool,
      clientDecidesWhatWork,
      clientDecidesHowWork,
      clientDetailedInstructions,
      clientTechnicalSpecfication,
      clientStandardProcedures,
      clientDecidesWhereWork,
      clientDecidesWhereWorkDetail,
      clientPremiseDueToSecurity,
      clientPremiseDueToConfidentiality,
      clientDecidesWhenWork,
      contractedSetHours,
      clientFundsTraining,
      workMyselfBool,
      workMyselfDetails,
      tasksByOthersBool,
      tasksByOthersDetail,
      sickReplacement,
      sickReplacementDetails,
      regularPay,
      annualLeave,
      permissionForTimeOff,
      sickPay,
      parentalLeave,
      pension,
      overtimePay,
      bonusPayments,
      fuelCard,
      otherBenefitsBool,
      otherBenefitsDetail,
      fundedSocialEvents,
      invoiceClient,
      paidIntoSeperateBank,
      payForProfessionalAdvice,
      responsibleForCorrectingUnsatisfactoryWork,
      insurance,
      profitOrLoss,
      provideToolsEquipmentBool,
      provideToolsEquipmentDetail,
      obligedToAcceptWork,
      freeToEarnOtherIncomeBool,
      freeToEarnOtherIncomeDetail,
      servicesAdvertised,
      incomeForecast
    };    
    step = this.state.step;
    switch(step) {
        case 1:
        return (
          <React.Fragment>
          
            <QuestionnaireQuestion
                handleChange={this.handleChange}
                nextStep = {this.nextStep}
                prevStep = {this.prevStep}
                questionText="Have you previously provided services to this Client immediately prior to this assignment?"
                fieldName="previouslyProvidedBool"
                detailFieldName="previouslyProvidedDetails"
                value= {values.previouslyProvidedBool}
                detailValue= {values.previouslyProvidedDetails}
                detailText="Please provide full details"
                detailNoText=""
                questionNum={1}
              />
              </React.Fragment>
        )
        case 2: 
        return(
        <React.Fragment>
            <QuestionnaireQuestion
                handleChange={this.handleChange}
                nextStep = {this.nextStep}
                prevStep = {this.prevStep}
                questionText="Have you previously worked for this Client as an employee?"
                fieldName="previouslyWorkedForClientAsEmployeeBool"
                value= {values.previouslyWorkedForClientAsEmployeeBool}
                detailFieldName="previouslyWorkedForClientAsEmployeeDetails"
                detailValue= {values.previouslyWorkedForClientAsEmployeeDetails}
                detailText="Please provide full details"
                detailNoText=""
                questionNum={2}
              />
               </React.Fragment>
        )
        case 3: 
        if (values.previouslyWorkedForClientAsEmployeeBool === "false")
        {
          this.setState({step: 4});
        }
        return(
          <React.Fragment>
            <QuestionnaireQuestion
                handleChange={this.handleChange}
                nextStep = {this.nextStep}
                prevStep = {this.prevStep}
                questionText="Will there be a major change to your working arrangements on this assignment compared with your previous employment? "
                fieldName="previouslyWorkedForClientAsEmployeeMajorChangeBool"
                value= {values.previouslyWorkedForClientAsEmployeeMajorChangeBool}
                detailFieldName="previouslyWorkedForClientAsEmployeeMajorChangeDetails"
                detailValue= {values.previouslyWorkedForClientAsEmployeeMajorChangeDetails}
                detailText="Please provide full details"
                detailNoText=""
                questionNum={3}
            />
          </React.Fragment>
        )
      
        case 4: 
        return(
        <React.Fragment>
            
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am an expert with specialist skills, knowledge, or experience "
            fieldName="expertBool"
            value= {values.expertBool}
            questionNum={4}
          />
           </React.Fragment>
        )
        case 5: 
        return(
        <React.Fragment>
            
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="Each day the Client decides what work I will do. "
            fieldName="clientDecidesWhatWork"
            value= {values.clientDecidesWhatWork}
            questionNum={5}
          />
           </React.Fragment>
        )
        case 6: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client tells me how to do my work. "
            fieldName="clientDecidesHowWork"
            value= {values.clientDecidesHowWork}
            questionNum={6}
          />
           </React.Fragment>
        )
        case 7: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client gives me detailed instructions in relation to each task. "
            fieldName="clientDetailedInstructions"
            value= {values.clientDetailedInstructions}
            questionNum={7}
          />
           </React.Fragment>
        )
        case 8: 
        return(
        <React.Fragment>
        
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client requires me to follow a technical specification when performing each task. "
            fieldName="clientTechnicalSpecfication"
            value= {values.clientTechnicalSpecfication}
            questionNum={8}
          />
           </React.Fragment>
        )
        case 9: 
        return(
        <React.Fragment>
        
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client requires me to follow a standard procedures manual when carrying out each task. "
            fieldName="clientStandardProcedures"
            value= {values.clientStandardProcedures}
            questionNum={9}
          />
           </React.Fragment>
        )
        case 10: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client decides where I do my work. "
            fieldName="clientDecidesWhereWork"
            value= {values.clientDecidesWhereWork}
            detailFieldName= "clientDecidesWhereWorkDetail"
            detailValue= {values.clientDecidesWhereWorkDetail}
            detailText="Explain why"
            questionNum={10}
          />
           </React.Fragment>
        )
        case 11: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I work at the Client's premises due to security requirements."
            fieldName="clientPremiseDueToSecurity"
            value= {values.clientPremiseDueToSecurity}
            questionNum={11}
          />
           </React.Fragment>
        )
        case 12: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I work at the Client's premises to preserve confidentiality. "
            fieldName="clientPremiseDueToConfidentiality"
            value= {values.clientPremiseDueToConfidentiality}
            questionNum={12}
          />
           </React.Fragment>
        )
        case 13: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client tells me when to do the work (i.e. when to start the work and when to finish each day). "
            fieldName="clientDecidesWhenWork"
            value= {values.clientDecidesWhenWork}
            questionNum={13}
          />
           </React.Fragment>
        )
        case 14: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am contracted to work a set number of hours "
            fieldName="contractedSetHours"
            value= {values.contractedSetHours}
            questionNum={14}
          />
           </React.Fragment>
        )
        case 15: 
        return(
        <React.Fragment>
            
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="The Client funds training for me to enable the work to be completed. "
            fieldName="clientFundsTraining"
            value= {values.clientFundsTraining}
            questionNum={15}
          />
           </React.Fragment>
        )
        case 16: 
        return(
        <React.Fragment>
            
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am required to do the work for the Client myself.  Guidance; Consider all of the tasks involved in completing the work and whether you could engage a helper to do preparation work or administrative tasks."
            fieldName="workMyselfBool"
            value= {values.workMyselfBool}
            detailFieldName="workMyselfDetails"
            detailValue= {values.workMyselfDetails}
            detailText="Explain why. For example security or confidentiality reasons, data protection, CRB, etc."
            questionNum={16}
          />
           </React.Fragment>
        )
        case 17: 
        return(
        <React.Fragment>
         
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am free to have other people complete tasks at my discretion.  Guidance; Consider whether some tasks such as preparation work or administrative tasks can be completed by others."
            fieldName="tasksByOthersBool"
            value= {values.tasksByOthersBool}
            detailFieldName="tasksByOthersDetail"
            detailValue= {values.tasksByOthersDetail}
            detailText="Please provide details of who pays substitute staff and on what terms."
            questionNum={17}
          />
           </React.Fragment>
        )
        case 18: 
        return(
        <React.Fragment>
            
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am free to have a suitably qualified or skilled replacement complete the work if I am sick or unable to do the work for some other reason."
            fieldName="sickReplacement"
            value= {values.sickReplacement}
            detailFieldName="sickReplacementDetails"
            detailValue= {values.sickReplacementDetails}
            detailNoText="Provide details why. For example security confidentiality, data protection, CRB, etc."
            questionNum={18}
          />
           </React.Fragment>
        )
        case 19: 
        return(
        <React.Fragment>
         
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I receive a regular wage, salary or pay, even if there is no work available"
            fieldName="regularPay"
            value= {values.regularPay}
            questionNum={19}
          />
           </React.Fragment>
        )
        case 20: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I receive or am entitled to receive paid annual leave."
            fieldName="annualLeave"
            value= {values.annualLeave}
            questionNum={20}
          />
           </React.Fragment>
        )
        case 21: 
        return(
        <React.Fragment>
          
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I have to ask permission to take time off rather than just telling the client out of courtesy."
            fieldName="permissionForTimeOff"
            value= {values.permissionForTimeOff}
            questionNum={21}
          />
           </React.Fragment>
        )
        case 22: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I receive or am entitled to receive sick pay."
            fieldName="sickPay"
            value= {values.sickPay}
            questionNum={22}
          />
           </React.Fragment>
        )
        case 23: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I receive or am entitled to receive paid parental leave (e.g maternity pay, paternity pay, shared parental leave pay) from the Client."
            fieldName="parentalLeave"
            value= {values.parentalLeave}
            questionNum={23}
          />
           </React.Fragment>
        )
        case 24: 
        return(
        <React.Fragment>
         
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am entitled to a pension as part of my terms with the Client."
            fieldName="pension"
            value= {values.pension}
            questionNum={24}
          />
           </React.Fragment>
        )
        case 25: 
        return(
        <React.Fragment>
         
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am entitled to overtime pay."
            fieldName="overtimePay"
            value= {values.overtimePay}
            questionNum={25}
          />
           </React.Fragment>
        )
        case 26: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am entitled to a bonus payments."
            fieldName="bonusPayments"
            value= {values.bonusPayments}
            questionNum={26}
          />
           </React.Fragment>
        )
        case 27: 
        return(
        <React.Fragment>
          
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am entitled to a fuel card."
            fieldName="fuelCard"
            value= {values.fuelCard}
            questionNum={27}
          />
           </React.Fragment>
        )
        case 28: 
        return(
        <React.Fragment>
          
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am entitled to receive or have access to other Client benefits.  Guidance; such as a company gym membership, childcare vouchers, private healthcare."
            fieldName="otherBenefitsBool"
            value= {values.otherBenefitsBool}
            detailFieldName="otherBenefitsDetail"
            detailValue= {values.otherBenefitsDetail}
            detailText="Please provide details of the benefits received."
            detailNoText=""
            questionNum={28}
          />
           </React.Fragment>
        )
        case 29: 
        return(
        <React.Fragment>
          
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I attend social functions organised and funded by the Client such as team-building events or Christmas parties."
            fieldName="fundedSocialEvents"
            value= {values.fundedSocialEvents}
            questionNum={29}
  
          />
           </React.Fragment>
        )
        case 30: 
        return(
        <React.Fragment>
          
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I invoice the client for the work I do."
            fieldName="invoiceClient"
            value= {values.invoiceClient}
            questionNum={30}
          />
           </React.Fragment>
        )
        case 31: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="Payment for the work goes into a bank account which is separate from my personal bank account."
            fieldName="paidIntoSeperateBank"
            value= {values.paidIntoSeperateBank}
            questionNum={31}
          />
           </React.Fragment>
        )
        case 32: 
        return(
        <React.Fragment>
           
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I pay for professional advice and accounting."
            fieldName="payForProfessionalAdvice"
            value= {values.payForProfessionalAdvice}
            questionNum={32}
          />
           </React.Fragment>
        )
        case 33: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am responsible for putting right any unsatisfactory work at my own expense and in my own time."
            fieldName="responsibleForCorrectingUnsatisfactoryWork"
            value= {values.responsibleForCorrectingUnsatisfactoryWork}
            questionNum={33}
          />
           </React.Fragment>
        )
        case 34: 
        return(
        <React.Fragment>
      
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="Professional indemnity insurance and/or other insurance will be held for the duration of the assignment."
            fieldName="insurance"
            value= {values.insurance}
            questionNum={34}
          />
           </React.Fragment>
        )
        case 35: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I can make a profit or loss on assignments."
            fieldName="profitOrLoss"
            value= {values.profitOrLoss}
            questionNum={35}
          />
           </React.Fragment>
        )
        case 36: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I provide the main tools and equipment needed to carry out my work."
            fieldName="provideToolsEquipmentBool"
            value= {values.provideToolsEquipmentBool}
            detailFieldName="provideToolsEquipmentDetail"
            detailValue= {values.provideToolsEquipmentDetail}
            detailText="Please indicate what tools and equipment you provide."
            detailNoText="Please indicate what tools are not provided by you, who provides them and confirm why."
            questionNum={36}
          />
           </React.Fragment>
        )
        case 37: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am obliged to accept work which the Client offers to me."
            fieldName="obligedToAcceptWork"
            value= {values.obligedToAcceptWork}
            questionNum={37}
          />
           </React.Fragment>
        )
        case 38: 
        return(
        <React.Fragment>
            
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="I am free to earn income from sources other than the service under examination in this assessment."
            fieldName="freeToEarnOtherIncomeBool"
            value= {values.freeToEarnOtherIncomeBool}
            detailFieldName="freeToEarnOtherIncomeDetail"
            detailValue= {values.freeToEarnOtherIncomeDetail}
            detailText="Please provide brief details of any other income earned or that you expect to earn during the course of this assignment."
            questionNum={38}
          />
           </React.Fragment>
        )
        case 39: 
        return(
        <React.Fragment>
<QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.nextStep}
            prevStep = {this.prevStep}
            questionText="My services will be advertised (e.g. via a website) to encourage income from other sources."
            fieldName="servicesAdvertised"
            value= {values.servicesAdvertised}
            questionNum={39}
          />
           </React.Fragment>
        )
        case 40: 
     
        return(
        <React.Fragment>
        <QuestionnaireQuestion
            handleChange={this.handleChange}
            nextStep = {this.props.nextStep}
            prevStep = {this.prevStep}
            questionText="A business plan will contain a forecast of the income I expect to receive over the next twelve months."
            fieldName="incomeForecast"
            value= {values.incomeForecast}
            questionNum={40}
          />
           </React.Fragment>
        )
      default:
      //this.handleSubmit();
      alert("An error occurred");
      console.log("An error occurred");
              
    }
  }
}

export default (AssignmentQuestionnaire);