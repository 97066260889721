import React, { Component } from 'react';
import './App.css';
import './bootstrap.min.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavBar from './components/NavBar' 
import { Container, Button, Col, Row } from 'react-bootstrap';
import {TextField}   from '@material-ui/core';
import { withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography'
import Form from './components/Pre-Questback/Form'
import Disclaimer from './components/Pre-Questback/Disclaimer';
import Introduction from './components/Pre-Questback/Introduction';
import {purple, green} from '@material-ui/core/colors';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import CustomizedTextbox from './components/controls/CustomizedTextbox';
import config from './config/config';



var appState =  {
	disclaimerRead: false,
  introductionRead: false,
  response: '',
  personalEmail: '',
  name: '',
  data: '',
}
//test override
//appState =  { 
//	disclaimerRead: true,
//  introductionRead: true,
//  response: '',
//  personalEmail: 'christopherpollock150@hotmail.com',
//  //personalEmail: '',
//  name: 'Chris Pollock',
//  //name: '',
//  data: '',
//} 

const theme = createMuiTheme({
  palette: {
    primary: {main: '#9678D3'}
  },
});

class App extends Component {
  constructor() {
    super();
    this.state = appState;
    
    this.introductionRead = this.introductionRead.bind(this);
  }
  disclaimerRead = () => {
    this.setState({disclaimerRead: true});
  }
  introductionRead() {
    this.setState({introductionRead:true});
  }
  componentDidMount() {
    // Call our fetch function below once the component mounts
  this.callBackendAPI()
    .then(res => this.setState({ data: "Online" }))
    .catch(err => {this.setState({data: "Offline"}); setTimeout(()=> this.componentDidMount(), 2000);} );
}
  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
callBackendAPI = async () => {
  
  const response = await fetch(config.ApiPath + 'test/test');
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(body.message) 
  }
  return body;
};
  
  handleChange = fieldName => field => {
    this.setState({[fieldName]: field.target.value})
  }


  render() {
    console.log('rendering app');
    var disclaimerRead = this.state.disclaimerRead;
    var introductionRead = this.state.introductionRead;
    var contractorName = this.state.name;
    var personalEmail = this.state.personalEmail;
    
    return (
      <CssBaseline>
        <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="App">
            <NavBar/>
            {/* <p className="App-intro">{this.state.data}</p> */}
            <Container className='pt-2 pt-md-4'>
              {!disclaimerRead ?  <Disclaimer onConfirm={this.disclaimerRead}></Disclaimer> : null}
              {disclaimerRead && !introductionRead ?  
                <Introduction onConfirm={this.introductionRead} handleChange={this.handleChange} personalEmail={personalEmail} contractorName={contractorName}></Introduction> 
                : 
                null
              }
              {disclaimerRead && introductionRead ?
                  <Form contractorName={contractorName} personalEmail={personalEmail}></Form>
                : 
                null
              }

              
            </Container>
          </div>
        </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </CssBaseline>

    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default (App);


