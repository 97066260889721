import React, {Component} from 'react'
import ContractorContactDetails from './ContractorContactDetails';
import Uploads from './Uploads';
import CompanyDetails from './CompanyDetails';
import AssignmentDetails from './AssignmentDetails';
import QuestionnaireQuestion from '../Questback/QuestionnaireQuestion';
import {Paper, CircularProgress} from '@material-ui/core'
import SubmitButton from '../controls/SubmitButton';
import Summary from './Summary'
import {ProgressBar, Container} from 'react-bootstrap'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import AssignmentQuestionnaire from './AssignmentQuestionnaire';
import axios from 'axios'
import config from '../../config/config';


class QuestbackForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      q1: this.props.initialValues.q1,
      q2: this.props.initialValues.q2,
      q3: this.props.initialValues.q3,
      q4: this.props.initialValues.q4,
      name: this.props.initialValues.contractorName,
      tradeOrProfession: '',
      email: this.props.initialValues.personalEmail, 
      tel: '',
      //address: '',
      addressLine1: '',
      addressLine2: '',
      town: '',
      county: '',
      postCode: '',
      contractFileType: '',
      contractFileName: '',
      companyName: '',
      //companyAddress: '',
      companyAddressLine1: '',
      companyAddressLine2: '',
      companyTown: '',
      companyCounty: '',
      companyPostCode: '',
      agency: '',
      client: '',
      startDate: '',
      placeOfWork: '',
      typeOfWork: '',
      expectedDuration: '',
      servicesProvided: '',
      assignmentRate: '',
      previouslyProvidedBool: '',
      previouslyProvidedDetails: '',
      previouslyWorkedForClientAsEmployeeBool: '',
      previouslyWorkedForClientAsEmployeeDetails: '',
      previouslyWorkedForClientAsEmployeeMajorChangeBool: '',
      previouslyWorkedForClientAsEmployeeMajorChangeDetails: '',
      expertBool: '',
      clientDecidesWhatWork: '',
      clientDecidesHowWork: '',
      clientDetailedInstructions: '',
      clientTechnicalSpecfication: '',
      clientStandardProcedures: '',
      clientDecidesWhereWork: '',
      clientDecidesWhereWorkDetail: '',
      clientPremiseDueToSecurity: '',
      clientPremiseDueToConfidentiality: '',
      clientDecidesWhenWork: '',
      contractedSetHours: '',
      clientFundsTraining: '',
      workMyselfBool: '',
      workMyselfDetails: '',
      tasksByOthersBool: '',
      tasksByOthersDetail: '',
      sickReplacement: '',
      sickReplacementDetails: '',
      regularPay: '',
      annualLeave: '',
      permissionForTimeOff: '',
      sickPay: '',
      parentalLeave: '',
      pension: '',
      overtimePay: '',
      bonusPayments: '',
      fuelCard: '',
      otherBenefitsBool: '',
      otherBenefitsDetail: '',
      fundedSocialEvents: '',
      invoiceClient: '',
      paidIntoSeperateBank: '',
      payForProfessionalAdvice: '',
      responsibleForCorrectingUnsatisfactoryWork: '',
      insurance: '',
      profitOrLoss: '',
      provideToolsEquipmentBool: '',
      provideToolsEquipmentDetail: '',
      obligedToAcceptWork: '',
      freeToEarnOtherIncomeBool: '',
      freeToEarnOtherIncomeDetail: '',
      servicesAdvertised: '',
      incomeForecast: ''
    }
    //this.state = {
    //  step: 6,
    //  q1: 'unknown',
    //  q2: 'unknown',
    //  q3: 'unknown',
    //  q4: 'unknown',
    //  name: 'Chris Pollock',
    //  tradeOrProfession: 'Web Developer',
    //  email: 'chris.pollock@weightmans.com', 
    //  tel: '07928906911',
    ////  address: '123 Demo Street \n Demo Road \n L1 1AA',
    //  addressLine1: '123 Fake Building',
    //  addressLine2: '345 Demo Street',
    //  town: 'Liverpool',
    //  county: 'Merseyside',
    //  postCode: 'L1 1AA',
    //  contractFileType: 'docx',
    //  contractFileName: 'EXAMPLEFILENAME',
    //  companyName: 'Weightmans',
    ////  companyAddress: 'Weightmans Road',
    //  companyAddressLine1: '123 Fake Building',
    //  companyAddressLine2: '345 Demo Street',
    //  companyTown: 'Kensington',
    //  companyCounty: 'London',
    //  companyPostCode: 'W8 5ED',
    //  agency: 'Some agency name here',
    //  client: 'Some client here',
    //  startDate: new Date(),
    //  placeOfWork: 'Liverpool',
    //  typeOfWork: 'Development',
    //  expectedDuration: 'Ages or not long',
    //  servicesProvided: 'Loads of services',
    //  assignmentRate: '£50/hr',
    //  previouslyProvidedBool: 'false',
    //  previouslyProvidedDetails: 'Yes',
    //  previouslyWorkedForClientAsEmployeeBool: 'true',
    //  previouslyWorkedForClientAsEmployeeDetails: '',
    //  previouslyWorkedForClientAsEmployeeMajorChangeBool: 'true',
    //  previouslyWorkedForClientAsEmployeeMajorChangeDetails: 'some details here',
    //  expertBool: 'true',
    //  clientDecidesWhatWork: 'true',
    //  clientDecidesHowWork: 'true',
    //  clientDetailedInstructions: 'true',
    //  clientTechnicalSpecfication: 'true',
    //  clientStandardProcedures: 'true',
    //  clientDecidesWhereWork: 'true',
    //  clientDecidesWhereWorkDetail: 'true',
    //  clientPremiseDueToSecurity: 'true',
    //  clientPremiseDueToConfidentiality: 'true',
    //  clientDecidesWhenWork: 'true',
    //  contractedSetHours: 'true',
    //  clientFundsTraining: 'true',
    //  workMyselfBool: 'true',
    //  workMyselfDetails: 'true',
    //  tasksByOthersBool: 'true',
    //  tasksByOthersDetail: 'true',
    //  sickReplacement: 'true',
    //  sickReplacementDetails: 'true',
    //  regularPay: 'true',
    //  annualLeave: 'true',
    //  permissionForTimeOff: 'true',
    //  sickPay: 'true',
    //  parentalLeave: 'true',
    //  pension: 'true',
    //  overtimePay: 'true',
    //  bonusPayments: 'true',
    //  fuelCard: 'true',
    //  otherBenefitsBool: 'true',
    //  otherBenefitsDetail: 'true',
    //  fundedSocialEvents: 'true',
    //  invoiceClient: 'true',
    //  paidIntoSeperateBank: 'true',
    //  payForProfessionalAdvice: 'true',
    //  responsibleForCorrectingUnsatisfactoryWork: 'true',
    //  insurance: 'true',
    //  profitOrLoss: 'true',
    //  provideToolsEquipmentBool: 'true',
    //  provideToolsEquipmentDetail: 'true',
    //  obligedToAcceptWork: 'true',
    //  freeToEarnOtherIncomeBool: 'true',
    //  freeToEarnOtherIncomeDetail: 'true',
    //  servicesAdvertised: 'true',
    //  incomeForecast: 'true'
    //}
     
}

  nextStep = () => {
    const { step } = this.state;
    this.setState({step: step + 1 });
  }
  prevStep = () => {
    const { step } = this.state;
    if (this.state.step === 8)
    {
      this.setState({step: step - 2}); //horrible hacky workaround for the 1 odd question flow.
    }
    else
    {
      this.setState({step: step - 1 });
    }
  }
  
  // Handlefields change
  handleChange = (input) => e => {
    console.log('questbackformchange' + input + '-' + e.target.value)
    this.setState({[input]: e.target.value});
  }

  handleChange2 = (field, date) => {
    this.setState({[field]: date})
  }

  handleSubmit = async () => {
    axios.post(
      config.ApiPath + 'test/SubmitForm',
      { data: this.state })
      .then((result) => {
        if (result.status === 200 && result.statusText === "OK" && result.data.success)
        {
          this.setState({submitted: true});
        }
        else
        {
          this.setState({submitted: true,
                         error: true,
                         errorMessage:result.data.errorMessage
                        });
        }
      })
  };

  render() {
    console.log('rendering questbackform')
    const { 
      step,
      q1,
      q2,
      q3,
      q4,
      name,
      tradeOrProfession,
      email,
      tel,
      addressLine1,
      addressLine2,
      town,
      county,
      postCode,
      contractFileType,
      contractFileName,
      companyName,
      //companyAddress,
      companyAddressLine1,
      companyAddressLine2,
      companyTown,
      companyCounty,
      companyPostCode,
      agency,
      client,
      startDate,
      placeOfWork,
      typeOfWork,
      expectedDuration,
      servicesProvided,
      assignmentRate,
      previouslyProvidedBool,
      previouslyProvidedDetails,
      previouslyWorkedForClientAsEmployeeBool,
      previouslyWorkedForClientAsEmployeeDetails,
      previouslyWorkedForClientAsEmployeeMajorChangeBool,
      previouslyWorkedForClientAsEmployeeMajorChangeDetails,
      expertBool,
      clientDecidesWhatWork,
      clientDecidesHowWork,
      clientDetailedInstructions,
      clientTechnicalSpecfication,
      clientStandardProcedures,
      clientDecidesWhereWork,
      clientDecidesWhereWorkDetail,
      clientPremiseDueToSecurity,
      clientPremiseDueToConfidentiality,
      clientDecidesWhenWork,
      contractedSetHours,
      clientFundsTraining,
      workMyselfBool,
      workMyselfDetails,
      tasksByOthersBool,
      tasksByOthersDetail,
      sickReplacement,
      sickReplacementDetails,
      regularPay,
      annualLeave,
      permissionForTimeOff,
      sickPay,
      parentalLeave,
      pension,
      overtimePay,
      bonusPayments,
      fuelCard,
      otherBenefitsBool,
      otherBenefitsDetail,
      fundedSocialEvents,
      invoiceClient,
      paidIntoSeperateBank,
      payForProfessionalAdvice,
      responsibleForCorrectingUnsatisfactoryWork,
      insurance,
      profitOrLoss,
      provideToolsEquipmentBool,
      provideToolsEquipmentDetail,
      obligedToAcceptWork,
      freeToEarnOtherIncomeBool,
      freeToEarnOtherIncomeDetail,
      servicesAdvertised,
      incomeForecast
    } = this.state;
    const values = { 
      step,
      q1,
      q2,
      q3,
      q4,
      name,
      tradeOrProfession,
      email,
      tel,
      addressLine1,
      addressLine2,
      town,
      county,
      postCode,
      contractFileType,
      contractFileName,
      companyName,
      //companyAddress,
      companyAddressLine1,
      companyAddressLine2,
      companyTown,
      companyCounty,
      companyPostCode,
      agency,
      client,
      startDate,
      placeOfWork,
      typeOfWork,
      expectedDuration,
      servicesProvided,
      assignmentRate,
      previouslyProvidedBool,
      previouslyProvidedDetails,
      previouslyWorkedForClientAsEmployeeBool,
      previouslyWorkedForClientAsEmployeeDetails,
      previouslyWorkedForClientAsEmployeeMajorChangeBool,
      previouslyWorkedForClientAsEmployeeMajorChangeDetails,
      expertBool,
      clientDecidesWhatWork,
      clientDecidesHowWork,
      clientDetailedInstructions,
      clientTechnicalSpecfication,
      clientStandardProcedures,
      clientDecidesWhereWork,
      clientDecidesWhereWorkDetail,
      clientPremiseDueToSecurity,
      clientPremiseDueToConfidentiality,
      clientDecidesWhenWork,
      contractedSetHours,
      clientFundsTraining,
      workMyselfBool,
      workMyselfDetails,
      tasksByOthersBool,
      tasksByOthersDetail,
      sickReplacement,
      sickReplacementDetails,
      regularPay,
      annualLeave,
      permissionForTimeOff,
      sickPay,
      parentalLeave,
      pension,
      overtimePay,
      bonusPayments,
      fuelCard,
      otherBenefitsBool,
      otherBenefitsDetail,
      fundedSocialEvents,
      invoiceClient,
      paidIntoSeperateBank,
      payForProfessionalAdvice,
      responsibleForCorrectingUnsatisfactoryWork,
      insurance,
      profitOrLoss,
      provideToolsEquipmentBool,
      provideToolsEquipmentDetail,
      obligedToAcceptWork,
      freeToEarnOtherIncomeBool,
      freeToEarnOtherIncomeDetail,
      servicesAdvertised,
      incomeForecast
    };

    var questionNum = step - 3;

    var progressPercentage = ((questionNum / 40) * 100) / 100 * 55; //hopefully this makes sense. HINT - /100 * 55 is because the bar is taken up by 15 + 15 + 15 already, so the questionNum/40 is just 45% of the overall bar.
    if (step == 0)
    {
      return (
        <Container>
        <Paper elevation={3} className='p-3 p-md-5 mb-5' style={{position:"relative"}}>
        Based on your responses, we need additional information in order to accurately assess your status, this includes: <br/>
          <ul>
            <li>
              Your contact details.
            </li>
            <li>
              Company details.
            </li>
            <li>
              Assignment details.
            </li>
            <li>
              A copy of the contract you have been given.
            </li>
          </ul>
          <br/>
          The final section is a questionnaire regarding your relationship to the company in order to help us establish your IR35 status.
          <br/><br/>
          Upon completion of this section your information will be sent to Weightmans who may need to contact you in order to complete the review.
          <br/><br/>
          <strong>This form should take around 30 minutes to complete. </strong>
          <br/><br/>  <br/><br/>
          <div style={{position:"absolute", 
                
                            bottom:"35px",
                            right:"35px",
                            
                            zIndex:99999}}>
                <SubmitButton onClick={this.nextStep}>
                </SubmitButton>
          </div>
       
          </Paper>
          </Container>
      )
    }
    if (step === 7 && this.state.submitted !== true) 
    {
        this.handleSubmit();
    }
    return (
              <Stepper activeStep={step-1} orientation="vertical" style={{backgroundColor:"#FAFAFA"}} className="pb-5 mb-5 ml-0 pl-0">
                  <Step>
                    <StepLabel>Contractor Contact Details</StepLabel>
                    <StepContent>
                        <ContractorContactDetails 
                          handleChange={this.handleChange}
                          handleChangeAlt={this.handleChange2}
                          nextStep = {this.nextStep}
                          prevStep = {this.prevStep}
                          values= {values}
                        />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Upload Contract</StepLabel>
                    <StepContent>
                    <Uploads 
                      handleChange = {this.handleChange}
                      handleChange2 = {this.handleChange2}
                      nextStep = {this.nextStep}
                      prevStep = {this.prevStep}
                      values = {values}
                      email = {this.state.email}
                    />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Company Details</StepLabel>
                    <StepContent>
                    <CompanyDetails 
                      handleChange={this.handleChange}
                      handleChangeAlt={this.handleChange2}
                      nextStep = {this.nextStep}
                      prevStep = {this.prevStep}
                      values= {values}
                    /> 
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Assignment Details</StepLabel>
                    <StepContent>
                      <AssignmentDetails 
                        handleChange={this.handleChange}
                        handleChangeAlt={this.handleChange2}
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        values= {values}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Assignment Questionnaire</StepLabel>
                    <StepContent>
                      <AssignmentQuestionnaire
                        handleChange={this.handleChange}
                        handleChangeAlt={this.handleChange2}
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        values= {values}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Review and Submit</StepLabel>
                    <StepContent>
                      <Summary 
                        values={values} 
                        nextStep={this.nextStep} 
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>Finished</StepLabel>
                    {this.state.submitted !== true
                    ? <StepContent>
                        <Paper elevation={2} className="p-5 text-center">
                          Please wait while your responses are submitted... <br/><br/>
                          <CircularProgress color="primary" />
                        </Paper>
                      </StepContent>
                    : <StepContent>
                        <Paper elevation={3} className="p-3 p-md-5">
                          <p>Thank you for using the IR35 Status tool.</p>

                          <p>As a result of our basic initial questionnaire, your IR35 status couldn't be determined and your details were forwarded to Weightmans LLP for further analysis.</p>

                          {this.state.error === true
                          ? <p>We tried to send you a copy of your results but ran into an issue: {this.state.errorMessage}</p> 
                          : <p>A copy of your responses and result has been emailed to you at 	<strong>{this.state.email}</strong> which you should receive shortly.<br></br><br></br></p>
                          }
                          <div className="mx-auto text-center">
                            <Button variant="contained" color="primary" className="weightmansbutton" onClick={() => window.location.reload(false)}>Start Over</Button>
                          </div>
                        </Paper>
                      </StepContent>}

                  </Step>
              </Stepper>
    )
  }
}

export default (QuestbackForm);