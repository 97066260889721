 import React, {Component} from 'react'
import { Paper, Button } from '@material-ui/core'

class Disclaimer extends Component {
    continue = e => {
       this.props.nextStep(); 
    }

    render() {
        return(
            <div>
                <Paper elevation={3} className="p-5" style={{position:"relative"}}>
                    <h1 className="mb-4">Disclaimer</h1>
                    <p>
                        This tool has been provided by Weightmans LLP. It is not known whether the tool will suit your particular needs and Weightmans LLP do not undertake any obligation to consider whether the information provided is either sufficient or appropriate for any particular actual circumstances. The tool is for guidance only and is not intended, and should not be used, as a substitute for legal advice tailored to your individual circumstances. Accordingly, you should take legal advice before entering into any agreement, or taking any action, based on the tool.
                    </p>
                    <p>
                        Weightmans LLP shall not be liable for any loss, injury, claim, liability or damage of any kind, to the maximum extent permitted by law, arising from the use of the tool. Furthermore Weightmans LLP shall not be liable for any special, indirect, incidental or consequential damages of any kind whatsoever (including, without limitation, legal fees) in any way due to the use of the tool. This includes, but is not limited to, liability for loss of profits, loss of business, depletion of goodwill or similar losses or pure economic loss, or for any special, indirect or consequential loss costs, damage, charges or expenses howsoever arising. Nothing shall exclude Weightmans’ liability for death or personal injury caused by their negligence, or for fraud or fraudulent misrepresentation in respect of the tool.

                    </p>
                    <p>
                        This tool is produced based on the law and practice as is the 1st May 2019.
                    </p>
                    <p>
                        Our Privacy Notice is available from the front page of our website <a href="https://www.weightmans.com" target="_blank" rel="noopener noreferrer">here</a>.
                    </p>
                    <div style={{position:"absolute", 
                        bottom:"35px",
                        right:"35px",
                        zIndex:99999}}>
                        <Button onClick={this.props.onConfirm}
                    variant="contained" color="primary" className="weightmansbutton">Continue</Button>
                    </div>
                </Paper>
                </div>


            
        )
    }
}
export default (Disclaimer);