import React, {Component} from 'react'
import Q1 from './q1';
import Q2 from './q2';
import Q3 from './q3';
import Q4 from './q4';
import QuestbackForm from '../Questback/QuestbackForm'
import {ProgressBar} from 'react-bootstrap'
import axios from 'axios';
import {Paper, Button} from '@material-ui/core'
import config from '../../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
var request = require('request');

class Form extends Component {
	constructor(props) {
		super(props)
		this.state =  {
			step: 1,
			q1: '',
			q2: '',
			q3: '',  
			q4: '',
			personalEmail: this.props.personalEmail,
			contractorName: this.props.contractorName,
			demoUrl: '',
			submitted: false
		}

		//this.state =  {
		//	step: 5,
		//	q1: 'unknown',
		//	q2: 'unknown',
		//	q3: 'unknown',  
		//	q4: 'unknown',
		//	personalEmail: this.props.personalEmail,
		//	contractorName: this.props.contractorName,
		//	demoUrl: '',
		//}


	}

  nextStep = () => {
    const { step } = this.state;
    this.setState({step: step + 1 });
  }
  prevStep = () => {
    const { step } = this.state;
		this.setState({step: step - 1 });

  }
  
  // Handlefields change
  handleChange = input => e => {
    this.setState({[input]: e.target.value});
  }

  setDemoUrl = (url) => {
	this.setState({demoUrl: url});
  }

  sendResult = (status) => {
	  var demoUrl = '';
	  var reference = this;
			console.log("sending result " + status);
			const { q1, q2, q3, q4} = this.state;
			var postData = this.state;
			postData.IR35Status = Decision_getIR35TriageStatus(q1, q2, q3, q4);
      axios.post(
					config.ApiPath + 
          'test/SubmitForm',
					{ data: this.state })
					.then((result) => {
						if (result.status === 200 && result.statusText === "OK" && result.data.success === true)
						{
							var curStep = this.state.step;
							this.setState({submitted: true, step: curStep + 1});
						}
						else
						{
							var curStep = this.state.step;
							this.setState({submitted: true, step: curStep + 1, error: true, errorMessage: result.data.errorMessage})
						}
					});
     
  }

  render() {
    
    const { step } = this.state;
    const { q1, q2, q3, q4, personalEmail, contractorName} = this.state;
    const values = { q1, q2, q3, q4, personalEmail, contractorName};
		var status = Decision_getIR35TriageStatus(q1, q2, q3, q4);
    var showQuestBackForm = (status === "amber");

    showQuestBackForm = true;
    switch(step) {
       case 1:
          return (
						<div>
							
  <ProgressBar striped variant="blue" now={25} key={1} />
							<Q1 
								handleChange={this.handleChange}
								nextStep = {this.nextStep}
								values= {values}
							/>
						</div>
            
            
          )
       case 2: 
            return (
							<React.Fragment>
  <ProgressBar striped variant="blue" now={50} key={1} />
								<Q2 
								handleChange={this.handleChange}
								nextStep = {this.nextStep}
								values= {values}
							/>
							</React.Fragment>
							
            )
       case 3:
            return (
              <React.Fragment>
  <ProgressBar striped variant="blue" now={75} key={1} />
								<Q3 
								handleChange={this.handleChange}
								nextStep = {this.nextStep}
								values= {values}
							/>
							</React.Fragment>
            )
       case 4:
            return (
							<React.Fragment>
  <ProgressBar striped variant="blue" now={100} key={1} />
								<Q4 
								handleChange={this.handleChange}
								nextStep = {this.nextStep}
								values= {values}
							/>
							</React.Fragment>
              
            )
			 case 5:
							switch(status)
							{
								case "red":
								this.sendResult(status); 
									return (
										<React.Fragment>
										<h1>Thank you...</h1>
										<Paper elevation={2} className="p-5 text-center">
											Please wait while your responses are submitted... <br/><br/>
											<CircularProgress color="primary" />
										</Paper>
									</React.Fragment>
									) 
								case "amber": 
									return (
										<React.Fragment>
												<QuestbackForm initialValues={values}></QuestbackForm>
										</React.Fragment>
									) 
								case "green":
								// if (this.state.demoUrl == '')
								// {
									this.sendResult(status); 
									return (
										<React.Fragment>
											<h1>Thank you...</h1>
											<Paper elevation={2} className="p-5 text-center">
												Please wait while your responses are submitted... <br/><br/>
												<CircularProgress color="primary" />
											</Paper>
										</React.Fragment>
									)
								// }
								default:
									break;
								
							}
						break;
							// return (<QuestbackForm></QuestbackForm>)
					default:
					var responseText = ""
					
					if (status === "red")
					{
						responseText = "Based on your answers, it looks like you meet the hypothetical employment test and therefore fall within IR35.";
					}
					if (status === "green")
					{
						responseText = "Based on your answers it looks like you fall outside of the hypothetical employment test and therefore outside of IR35.";	
					}

					return (
						<React.Fragment>
							<h1>Result</h1>
							
							<Paper elevation={3} className="p-5">
								Thank you for using the IR35 status tool. <br></br><br></br>

								{responseText} <br></br><br></br>
								{this.state.error === true
								? <>We tried to send you a copy of your results but ran into an issue: {this.state.errorMessage}</> 
								: <>A copy of your responses and result has been emailed to you at 	<strong>{this.state.personalEmail}</strong> which you should receive shortly.<br></br><br></br></>
								}
								<div className="mx-auto text-center mt-4">
								<Button 
										onClick={() => window.location.reload(false)}
										variant="contained" 
										color="primary" 
										className="weightmansbutton"
								>
									Start Over
								</Button>
								</div>
								
							</Paper>
						</React.Fragment>
					);
    }
  }
}

export default (Form);

function showDemoURL(url)
{
	this.setState({demoUrl: url});
}
function Decision_getIR35TriageStatus(q1, q2, q3, q4)
		{
			//DecisionTable HitPolicy: "FIRST"; Request Output: "getIR35TriageStatus"
			var status = null;
			//DecisionTable Rule Definitions Begin
      //Rule #: 1
      // alert(q1 + ", " + q2 + ", " + q3 + ", " + q4);

			if (((q2) === "yes") && status === null)
			{
				status = "green";
			}
			//Rule #: 2
			if (((q3) === "yes") && status === null)
			{
				status = "green";
			}
			//Rule #: 3
			if (((q1) === "no") && status === null)
			{
				status = "green";
			}
			//Rule #: 4
			if (((q1) === "yes" && (q2) === "no" && (q3) === "no" && (q4) === "yes")  && status === null)
			{
				status = "red";
			}
			//Rule #: 5
			if (((q1) === "yes" && (q2) === "no" && (q3) === "no" && (q4) === "no")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 6
			if (((q1) === "yes" && (q2) === "no" && (q3) === "no" && (q4) === "unknown")  && status === null)
			{
				status = "amber";
			}
			//Rule #: 7
			if (((q1) === "yes" && (q2) === "no" && (q3) === "unknown" && (q4) === "yes") && status === null)
			{
				status = "red";
			}
			//Rule #: 8
			if (((q1) === "yes" && (q2) === "no" && (q3) === "unknown" && (q4) === "no") && status === null)
			{
				status = "amber";
			}
			//Rule #: 9
			if (((q1) === "yes" && (q2) === "no" && (q3) === "unknown" && (q4) === "unknown") && status === null)
			{
				status = "amber";
			}
			//Rule #: 10
			if (((q1) === "yes" && (q2) === "unknown" && (q3) === "no" && (q4) === "yes") && status === null)
			{
				status = "red";
			}
			//Rule #: 11
			if (((q1) === "yes" && (q2) === "unknown" && (q3) === "no" && (q4) === "no") && status === null)
			{
				status = "amber";
			}
			//Rule #: 12
			if (((q1) === "yes" && (q2) === "unknown" && (q3) === "no" && (q4) === "unknown") && status === null)
			{
				status = "amber";
			}
			//Rule #: 13
			if (((q1) === "yes" && (q2) === "unknown" && (q3) === "unknown" && (q4) === "yes") && status === null)
			{
				status = "amber";
			}
			//Rule #: 14
			if (((q1) === "yes" && (q2) === "unknown" && (q3) === "unknown" && (q4) === "no") && status === null)
			{
				status = "amber";
			}
			//Rule #: 15
			if (((q1) === "yes" && (q2) === "unknown" && (q3) === "unknown" && (q4) === "unknown") && status === null)
			{
				status = "amber";
			}
			//Rule #: 16
			if (((q1) === "unknown" && (q2) === "no" && (q3) === "no" && (q4) === "yes") && status === null)
			{
				status = "red";
			}
			//Rule #: 17
			if (((q1) === "unknown" && (q2) === "no" && (q3) === "no" && (q4) === "no")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 18
			if (((q1) === "unknown" && (q2) === "no" && (q3) === "no" && (q4) === "unknown")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 19
			if (((q1) === "unknown" && (q2) === "no" && (q3) === "unknown" && (q4) === "yes")	 && status === null)
			{
				status = "red";
			}
			//Rule #: 20
			if (((q1) === "unknown" && (q2) === "no" && (q3) === "unknown" && (q4) === "no")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 21
			if (((q1) === "unknown" && (q2) === "no" && (q3) === "unknown" && (q4) === "unknown")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 22
			if (((q1) === "unknown" && (q2) === "unknown" && (q3) === "no" && (q4) === "yes")	 && status === null)
			{
				status = "red";
			}
			//Rule #: 23
			if (((q1) === "unknown" && (q2) === "unknown" && (q3) === "no" && (q4) === "no")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 24
			if (((q1) === "unknown" && (q2) === "unknown" && (q3) === "no" && (q4) === "unknown")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 25
			if (((q1) === "unknown" && (q2) === "unknown" && (q3) === "unknown" && (q4) === "yes")	 && status === null)
			{
				status = "red";
			}
			//Rule #: 26
			if (((q1) === "unknown" && (q2) === "unknown" && (q3) === "unknown" && (q4) === "no")	 && status === null)
			{
				status = "amber";
			}
			//Rule #: 27
			if (((q1) === "unknown" && (q2) === "unknown" && (q3) === "unknown" && (q4) === "unknown")	 && status === null)
			{
				status = "amber";
      }
			console.log(status)
      return status;
		}